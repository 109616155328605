<template>
  <b-card>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="LogInIcon" />
          <span>IN Area Only</span>
        </template>

        <b-alert  variant="info" show>
          <!-- <div class="alert-body font-small-2">
            <p v-text="'asd'" />
          </div> -->
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="position-absolute"
            style="top: 10; right: 10"
          />
        </b-alert>
        <b-card-text>
          Melihat data visitor yang hanya <code>sedang berada di lokasi area PT SAL.</code>
        </b-card-text>
        <b-card-text>
          output file <code>excel</code>.
        </b-card-text>
        <ReportingINOnly/>
      </b-tab>
      <b-tab active>
        <template #title>
          <feather-icon icon="LinkIcon" />
          <span>ALL Base on Clock IN</span>
        </template>

        <b-alert  variant="info" show>
          <!-- <div class="alert-body font-small-2">
            <p v-text="'asd'" />
          </div> -->
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="position-absolute"
            style="top: 10; right: 10"
          />
        </b-alert>
        <b-card-text>
          Melihat <code>semua data visitor</code> berdasarkan Rentang Waktu <code>( Periode )</code> jam masuk visitor.
        </b-card-text>
        <b-card-text>
          output file <code>excel</code>.
        </b-card-text>
        <ReportingINOUT/>
      </b-tab>
      <b-tab disabled>
        <template #title>
          <feather-icon icon="GitPullRequestIcon" />
          <span>Base on Badge Type</span>
        </template>

        <b-card-text>
          Halvah dessert fruitcake toffee oat cake tart oat cake topping jelly beans. Pudding sweet pie pastry lemon
          drops
          jujubes danish pie gingerbread. Liquorice powder wafer.
        </b-card-text>
      </b-tab>
      <b-tab disabled>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Multiple Badge Type</span>
        </template>

        <b-card-text>
          Chocolate croissant cupcake croissant jelly donut. Cheesecake toffee apple pie chocolate bar biscuit tart
          croissant.
          Lemon drops danish cookie. Oat cake macaroon icing tart lollipop cookie sweet bear claw.
        </b-card-text>
        <b-card-text>
          Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies dragée. Chocolate bar liquorice cheesecake
          cookie
          chupa chups marshmallow oat cake biscuit. Dessert toffee fruitcake ice cream powder tootsie roll cake.
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab, BCardText } from 'bootstrap-vue';
import ReportingINOUT from './ReportingINOUT.vue';
import ReportingINOnly from './ReportingINOnly.vue';

export default {
  components: {
    ReportingINOUT,
    ReportingINOnly,
    BCard,
    BCardText,
    BTabs,
    BTab,
  },
};
</script>