<template>
  <div>
    <b-card no-body class="border-primary py-1 px-1">
      <div class="divider my-2">
        <div class="divider-text">
          <h2>IN Area Only</h2>
          <b-spinner class="mr-1" small type="grow" variant="success" />
          <b-spinner class="mr-1" small type="grow" variant="success" />
          <b-spinner small type="grow" variant="success" />
        </div>
      </div>
      <validation-observer ref="formRules" tag="form">
        <b-form @submit.prevent="generateExcel">
          <b-button block v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="success" class="mr-1">
            <feather-icon icon="CheckCircleIcon" class="mr-25" />
            Generate File
          </b-button>
        </b-form>
      </validation-observer>
      <div class="divider my-2">
        <div class="divider-text">

        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BCol,
  BCard,
  BCardFooter,
  BCardText
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VSelect from 'vue-select';
import moment from 'moment';
import { Indonesian } from 'flatpickr/dist/l10n/id.js';
import flatPickr from 'vue-flatpickr-component';
import useJwt from '@/auth/jwt/useJwt';
import { v4 as uuidv4, NIL as uuidnil } from 'uuid';
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig';
import { getUserData } from '@/auth/utils';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Indonesian,
    flatPickr,
    BCard,
    BCardFooter,
    BCardText,
    VSelect,
    FeatherIcon
  },
  directives: {
    Ripple
  },
  watch: {
    'form.by_agent': function () {
      const agent = this.form.by_agent;
      if (agent === 'SLB') {
        this.flag_agent = false;
        this.form.capacity_truck = null;
      } else {
        this.form.jenis_truck = null;
        this.flag_agent = true;
      }
      this.API_SAL = this.MAP_API[agent];
    }
  },
  data() {
    return {
      component_vi_segmen: 1,
      dpconfig: {
        // default
        wrap: true,
        enableTime: false,
        time_24hr: false,
        // altInput: true,
        // dateFormat: 'Y-m-d H:i',
        altInput: true,
        altFormat: 'j F Y',
        // altFormat: 'j F Y - h:m K',
        dateFormat: 'Y-m-d',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      dpconfigRevisi: {
        wrap: true,
        enableTime: false,
        time_24hr: false,
        altInput: true,
        altFormat: 'j F Y',
        // altFormat: 'j F Y - h:m K',
        dateFormat: 'Y-m-d',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      configs: {
        end: {
          wrap: true,
          enableTime: false,
          time_24hr: false,
          altInput: true,
          altFormat: 'j F Y',
          dateFormat: 'Y-m-d',
          locale: Indonesian,
          minDate: null
        }
      },
      flag_agent: true,
      form: {
        // by_agent: null,
        start_date: null,
        end_date: null,
        // capacity_truck: 'ALL',
        // jenis_truck: 'ALL',
        // segmen: null,
        // qrcode_status: null
      },
      MAP_API: {
        TEM: 'form1_land_vehicle/submit/excel/new',
        PATRA: 'form1_land_vehicle/submit/excel/new',
        'PT PIL': 'form2_land_vehicle/submit/excel/new',
        SLB: 'form3_land_vehicle/submit/excel/new'
      },
      API_SAL: null,
      BACKEND_URI_SAL: process.env.VUE_APP_BACKEND_URL,

      option: {
        lock_group_account: true,
        by_agents: [
          { text: 'TEM', value: 'TEM' },
          { text: 'PATRA', value: 'PATRA' },
          { text: 'PT PIL', value: 'PT PIL' },
          { text: 'SLB', value: 'SLB' }
        ],
        capacity_trucks: [
          { text: 'Kapasitas Truck', value: 'null' },
          { text: '5.000 Liter', value: '5000' },
          { text: '10.000 Liter', value: '10000' },
          { text: '16.000 Liter', value: '16000' },
          { text: '20.000 Liter', value: '20000' },
          { text: 'ALL', value: 'ALL' }
        ],
        jenis_trucks: [
          { text: 'Jenis Truck', value: 'null' },
          { text: 'TRUCK', value: 'TRUCK' },
          { text: 'LONGBED', value: 'LONGBED' },
          { text: 'TRAILER', value: 'TRAILER' },
          { text: 'MOBIL BOX', value: 'MOBIL BOX' },
          { text: 'MOBIL', value: 'MOBIL' },
          { text: 'BIS', value: 'BIS' },
          { text: 'ALL', value: 'ALL' }
        ],
        segmens: [
          { text: 'ELNUSA LMP', value: 'ELNUSA LMP' },
          { text: 'MI (LMP)', value: 'MI (LMP)' },
          { text: 'DAS (Cementing)', value: 'DAS (Cementing)' },
          // { text: 'Facility (SLB)', value: 'Facility (SLB)' },
          { text: 'RPI', value: 'RPI' },
          { text: 'ELNUSA (Cementing)', value: 'ELNUSA (Cementing)' },
          { text: 'SAL', value: 'SAL' }
        ],
        qrcode_statuss: [{ text: 'Expired', value: 'Expired' }]
      }
    };
  },
  created() {
    const userData = getUserData();
    const group = userData.group?.name ?? null;
    if (!!group) {
      /* slice data and disable group account change */
      this.option.lock_group_account = true;
      this.form.by_agent = `${group}`;
    } else {
      this.option.lock_group_account = false;
    }

    // segmen change
    if (!this.$can('manage', 'all')) {
      const segmens = [];
      this.$formSegmenSlb.forEach((value) => {
        if (this.$can(value.key, '')) {
          segmens.push({
            text: value.key,
            value: value.key
          }); /* elnusa cementing membawahi elnusa lmp (can create and approve) */
          if (value.key === 'ELNUSA (Cementing)') {
            segmens.push({ text: 'ELNUSA LMP', value: 'ELNUSA LMP' });
          }
        }
      });
      this.form.segmen = segmens[0].value;
      this.option.segmens = segmens;
    }
    // if (!this.$can('manage', 'all')) {
    //   const qrcode_statuss = []
    //   this.$formQrcodeStatus.forEach((value) => {
    //     if (this.$can(value.key, '')) {
    //       qrcode_statuss.push({
    //         text: value.key,
    //         value: value.key
    //       }) /* elnusa cementing membawahi elnusa lmp (can create and approve) */
    //       if (value.key === 'ELNUSA (Cementing)') {
    //         qrcode_statuss.push({ text: 'ELNUSA LMP', value: 'ELNUSA LMP' })
    //       }
    //     }
    //   })
    //   this.form.qrcode_status = qrcode_statuss[0].value
    //   this.option.qrcode_statuss = qrcode_statuss
    // }
  },
  methods: {
    onStartChange(selectedDates, dateStr, instance) {
      this.configs.end.minDate = dateStr;
    },
    onEndChange(selectedDates, dateStr, instance) {
      // 2022-08-22T13:34:00.000Z
      this.dpconfig.maxDate = dateStr;
    },
    selectAllSegmen(flag) {
      if (flag === true) {
        /* selectAll */
        const segmens = [];
        this.option.segmens.forEach((element) => {
          segmens.push(element.value);
        });
        this.form.segmen = segmens;
      } else {
        /* deSelectAll */
        this.form.segmen = null;
      }
    },
    selectAllQrcodeStatus(flag) {
      if (flag === true) {
        /* selectAll */
        const qrcode_statuss = [];
        this.option.qrcode_statuss.forEach((element) => {
          qrcode_statuss.push(element.value);
        });
        this.form.qrcode_status = qrcode_statuss;
      } else {
        /* deSelectAll */
        this.form.qrcode_status = null;
      }
    },
    resetForm() {
      // this.form.by_agent = null
      this.form.capacity_truck = null;
      this.form.jenis_truck = null;
      this.form.start_date = null;
      this.form.clock_out = null;
    },
    generateExcel() {
      const payload = this.form;
      console.log(payload);
      return new Promise((resolve, reject) => {
        this.$refs.formRules.validate().then((success) => {
          if (success) {
            this.requestGet();
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    requestGet() {
      useJwt.http
        .get('vw_visitor_reporting_all/in-area', { params: this.form })
        .then((response) => {
          const uri = `${this.BACKEND_URI_SAL}/${response.data.link_file_excel}`;
          console.log(uri);
          window.open(uri);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style></style>